import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import {
  Grid,
  Icon,
  Modal,
  Slide,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import useTracking from '~/services/tracking'
import { CAMPAIGN_STATUS } from '~/helpers/constants'
import {
  camelToSnakeCase,
  htmlClean
} from '~/helpers/strings'
import BrandLogo from '~/assets/images/logo/brands'
import ModalMgm from '~/components/modal-mgm'

export default ({ isHome }) => {
  const classes = useStyles()
  const theme = useTheme()
  const tracking = useTracking()

  const [menuOpen, setMenuOpen] = React.useState(false)

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const {
    campaignId,
    consumerMenu,
    campaignStatus
  } = useSelector(state => state.campaignReducers.campaign)

  const RenderMenuItem = ({ to, trackingSlug, Icon, Title, target = false }) => (
    <Grid item align='center'>
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        component={Link}
        to={to}
        className={classes.LINKS}
        target={target || '_self'}
        onClick={() => tracking({ event: trackingSlug })}
      >
        {Icon} <span dangerouslySetInnerHTML={{ __html: htmlClean(Title) }} />
      </Typography>
    </Grid>
  )

  return (
    <div className={classes.HEADER_CONTAINER}>
      <Grid container wrap='nowrap' spacing={5} alignItems='center' justify='space-between'>
        <Grid item>
          <Grid container direction='row' alignItems='center' spacing={2} justify='flex-start'>
            {(campaignStatus === CAMPAIGN_STATUS.ENABLED && typeof consumerMenu === 'object' && consumerMenu.length > 0) && (
              <Grid item>
                <Grid
                  className={classes.MENU_LOGO_BG}
                  onClick={() => {
                    tracking({ event: !menuOpen ? 'header_menu_abre_click' : 'header_menu_sair_click' })
                    setMenuOpen(!menuOpen)
                  }}
                >
                  {menuOpen ? <CloseIcon /> : <MenuIcon />}
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Link
                to={`/${campaignId}/`}
                onClick={() => tracking({ event: 'header_logo_click' })}
                aria-label='Voltar para a home'
                className={clsx(classes.MENU_LOGO_BG, classes.LOGO)}
              >
                <BrandLogo color={theme.palette.primary.main} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ModalMgm showOnlyIcon />
        </Grid>
      </Grid>

      <Modal
        className={classes.MENU_OPENED}
        open={menuOpen}
        onClose={() => {
          tracking({ event: 'header_menu_sair_click' })
          setMenuOpen(false)
        }}
        BackdropProps={{ className: classes.MENU_OPENED_BG }}
        closeAfterTransition
      >
        <Slide direction='right' in={menuOpen} mountOnEnter unmountOnExit>
          <Grid container direction='column' wrap='nowrap' spacing={4} className={classes.MENU_OPENED_BASE}>
            {(typeof consumerMenu === 'object' && consumerMenu.length > 0) && consumerMenu.map((menuItem, ind) => {
              const isExternal = !!(menuItem.link[0] !== '/')

              return (
                <RenderMenuItem
                  key={`menu_item_${ind}`}
                  to={!isExternal ? `/${campaignId}${menuItem?.link}` : { pathname: menuItem?.link }}
                  trackingSlug={`header_menu_item_${menuItem?.item}_click`}
                  Icon={<Icon>{camelToSnakeCase(menuItem?.icon)}</Icon>}
                  Title={menuItem?.description}
                  target={isExternal ? '_blank' : '_self'}
                />
              )
            })}
          </Grid>
        </Slide>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  HEADER_CONTAINER: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '12px 24px'
  },
  HEADER_FIXED: {
    position: 'fixed',
    backgroundColor: theme?.themeCustom?.COR_FUNDO
  },
  LOGO_NO_MENU: {
    justifyContent: 'center'
  },
  MENU_LOGO_BG: {
    position: 'relative',
    zIndex: 9999,
    color: theme.palette.primary.main,
    background: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: 4,
    boxShadow: '0px 4px 24px -8px #00000066',
    display: 'flex',
    cursor: 'pointer',
    '& .MuiIcon-root': {
      fontSize: '1rem'
    }
  },
  LOGO: {
    padding: theme.spacing(1, 2),
    '& svg, & img': {
      height: '24px',
      width: 'auto',
      maxWidth: '100%',
      display: 'block'
    }
  },
  MENU: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  MENU_OPENED: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  MENU_OPENED_BASE: {
    outline: 'none',
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .material-icons': {
        marginRight: '0.2rem'
      }
    }
  },
  MENU_OPENED_BG: {
    backgroundColor: `${theme.palette.background.default}f5 !important`,
    zIndex: 2
  },
  LINKS: {
    color: theme.themeCustom?.COR_CONTEUDO_PRIMARIA || theme.palette.primary.main
  }
}))
